/* eslint-disable no-empty */
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '~/stores/user'
import { useSubscriptionsStore } from '~/stores/subscriptions'
import { usePromotionStore } from '~/stores/promotion'
import {
  PROMOTION_VALIDATION_ERRORS,
  PROMO_CODE_STORAGE_KEY,
  TRACKING_LISTS
} from '~/utils/constants'

export default {
  data() {
    return {
      validatingCode: false
    }
  },
  computed: {
    ...mapState(usePromotionStore, ['promotion', 'isPromoCodeValid']),
    ...mapState(useUserStore, ['loggedInUser']),
    ...mapState(useSubscriptionsStore, ['cart'])
  },
  methods: {
    ...mapActions(usePromotionStore, {
      setPromotionValidity: 'setPromotionValidity',
      setPromotionCode: 'setPromotionCode',
      validateCode: 'validatePromoCode'
    }),
    ...mapActions(useSubscriptionsStore, ['addToCart', 'clearCart']),
    getPromoCode() {
      return (
        this.$route.query.promocode ||
        this.$localStorage.getItem(PROMO_CODE_STORAGE_KEY)
      )
    },
    async validatePromoCode(promoCode) {
      const trimmedPromoCode = (promoCode || '').trim()

      await this.validateCode({
        promotionCode: trimmedPromoCode,
        isAuthenticated: !!this.loggedInUser
      })

      this.$localStorage.addItem(PROMO_CODE_STORAGE_KEY, trimmedPromoCode, 1)
    },
    async addPromotionToCart() {
      const { productInfo, promoCode, campaignInfo } = this.promotion

      await this.addToCart({
        productUid: productInfo.uid,
        promotionCartItem: {
          promotionCode: promoCode,
          campaignVersion: campaignInfo.version
        }
      })

      this.pushTrackingEvent()
    },
    onValidationError(
      error,
      promoCode,
      customErrorLabel = 'messages.errorOccuredMessage',
      noNotification
    ) {
      this.setPromotionValidity(false)
      this.setPromotionCode(promoCode)
      this.clearCart()

      const errorLabel = this.$error.getLabel(error)
      const isPromoValidationError =
        PROMOTION_VALIDATION_ERRORS.includes(errorLabel)
      const label = isPromoValidationError ? errorLabel : customErrorLabel

      if (!noNotification) {
        this.$toast.error(this.$label(label))
      }

      if (!isPromoValidationError) this.$sentryCaptureException(error)
    },
    pushTrackingEvent() {
      try {
        const {
          priceInfo: { finalPrice, originalPrice },
          productInfo: { name, uid },
          promoCode
        } = this.promotion

        const item = {
          price: originalPrice,
          id: uid,
          name,
          ...TRACKING_LISTS.promo,
          discount: originalPrice - finalPrice,
          coupon: promoCode
        }
        this.$gtmTracking.addToCart(item, finalPrice)
      } catch {}
    }
  }
}
